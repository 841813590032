<template>
    <v-container>
        <v-card>
            <v-card-title>
                <div class="d-flex align-center" style="gap: 16px;">
                    <NavButton />
                    <h2 class="text-h6 text-uppercase font-weight-bold">Registros de fondo</h2>
                </div>
            </v-card-title>

            <v-divider />

            <v-card-text>
                <v-row v-if="fondo">
                    <v-col cols="12" md="12">
                        <v-card style="height: 100%;">
                            <v-card-text>
                                <dl>
                                    <dt class="font-weight-bold">Institución:</dt>
                                    <dd>{{ fondo.Institucion.nombre ?? '-' }}</dd>
        
                                    <dt class="font-weight-bold mt-2">Nombre:</dt>
                                    <dd>{{ fondo.nombre ?? '-' }}</dd>
        
                                    <dt class="font-weight-bold mt-2">Motivo:</dt>
                                    <dd>{{ fondo.motivo ?? '-' }}</dd>
                                </dl>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-card style="height: 100%;">
                            <v-card-text>
                                <div class="d-flex flex-wrap flex-column justify-space-between flex-sm-nowrap flex-sm-row" style="gap: 16px;">
                                    <div class="text-center flex-grow-1">
                                        <h6 class="text-caption text-uppercase font-weight-bold" style="color: #7a7a7c">Monto reservado</h6>
                                        <p class="mb-0 text-h6" style="color: var(--v-info-darken2)">
                                            {{ toMoneyFormat(fondo.monto_establecido) }}
                                        </p>
                                    </div>
                                    <v-divider class="d-none d-sm-block" vertical />
                                    <div class="text-center flex-grow-1">
                                        <h6 class="text-caption text-uppercase font-weight-bold" style="color: #7a7a7c">Monto ejecutado</h6>
                                        <p class="mb-0 text-h6" style="color: var(--v-info-darken2)">
                                            {{ toMoneyFormat(fondo.monto_establecido - fondo.monto_disponible) }}
                                        </p>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <DataTableComponent 
                    :items="registros"
                    :headers="headers"
                    :show_loading="fondoConRegistros.isLoading"
                    :total_registros="registrosTotales"
                    @paginar="manejarPaginacion"
                    v-models:pagina="paginacion.pagina"
                    v-models:select="paginacion.registrosPorPagina"
                    class="mt-4"
                    dense
                    no-gutters
                >
                    <template v-slot:item.mes_aplicacion="{ item }">
                        {{ item.mes_aplicacion | moment("MMMM") }}
                    </template>
                    <template v-slot:item.fecha="{ item }">
                        {{ item.fecha | moment("DD/MM/YYYY") }}
                    </template>
                    <template v-slot:item.monto="{ item }">
                        {{ toMoneyFormat(item.monto) }}
                    </template>
                    <template v-slot:item.acciones="{ item }">
                        <div class="d-flex align-center justify-center" style="gap: 8px;">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-on="on" v-bind="attrs" icon :to="'/reporteria-compra-en-linea/documentos-registro/' + item.id">
                                        <v-icon color="secondary">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Ver documentos</span>
                            </v-tooltip>
                        </div>
                    </template>
                </DataTableComponent>
            </v-card-text>
        </v-card>
    </v-container>

</template>
<script>
import { createPageable, setPageableResponse, togglePageable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import NavButton from '@/components/utils/NavButton.vue';
import { toMoneyFormat } from '@/utils/data';

export default {
    components: { NavButton, DataTableComponent },
    data: () => ({
        paginacion: {
            pagina: 1,
            registrosPorPagina: 10,
        },
        fondoConRegistros: createPageable([], 10),
        idFondo: null,
    }),
    computed: {
        headers() {
            return [
                { align: "center", sortable: false, text: "Fecha de documento", width: '10%', value: "fecha" },
                { align: "center", sortable: false, text: "Mes a reportar", width: '10%', value: "mes_aplicacion" },
                { align: "center", sortable: false, text: "Proveedor", width: '20%', value: "nombre_proveedor" },
                { align: "center", sortable: false, text: "Número documento", width: '20%', value: "numero_documento" },
                { align: "center", sortable: false, text: "Observación", width: '20%', value: "observacion" },
                { align: "center", sortable: false, text: "Monto total ($)", width: '15%', value: "monto" },
                { align: "center", sortable: false, text: "Acciones", width: '5%', value: "acciones" },
            ];
        },
        registrosTotales() {
            return this.fondoConRegistros.pagination.total_rows ?? 0;
        },
        registros() {
            return this.fondoConRegistros.data?.registros ?? [];
        },
        fondo() {
            return this.fondoConRegistros.data?.fondo;
        },
    },
    methods: {
        // UI
        toMoneyFormat,
        manejarPaginacion(paginacion) {
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
            this.paginacion = { pagina, registrosPorPagina }

            this.cargarRegistros();
        },
        // DATA
        async cargarRegistros() {
            const payload = {
                pagination: true,
                page: this.paginacion.pagina,
                per_page: this.paginacion.registrosPorPagina,
            };

            togglePageable(this.fondoConRegistros);
            const { data, headers } = await this.services.ReporteriaCompraEnLinea.cargarRegistrosPorFondo(this.idFondo, payload);
            setPageableResponse(this.fondoConRegistros, data, headers);
        },
    },
    created() {
        const idFondoUrl = this.$route.params.id_fondo;
        
        if (isFinite(idFondoUrl)) {
            this.idFondo = idFondoUrl;
            this.cargarRegistros();
        }
    },
}
</script>